<template>
  <div>
    <el-dialog
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="100px">
        <el-form-item label="权限名称：">
          <el-input v-model="form.authTitle" placeholder="请输入权限名称" clearable style="width:60%"></el-input>
        </el-form-item>
        <el-form-item label="KEY：">
          <el-input v-model="form.authKey" placeholder="请输入KEY" clearable style="width:60%"></el-input>
        </el-form-item>
        <el-form-item label="是否为菜单：">
          <el-radio-group v-model="form.isMenu">
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm()" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {businessAuthOperateCreate, businessAuthOperateEdit} from '@/api/businessManage.js';
export default {
  name: "createAuth",
  data() {
    return {
      dialogFormVisible:false,
      title:"新增权限",
      form:{
        authTitle:"",
        authKey:"",
        isMenu:1,
        authType:0,
        routeId:""
      },
    };
  },
  methods: {
    async isShow(val,type) {
      this.dialogFormVisible = true
      if(type == "edit"){
        this.title = "修改权限"
        this.form.authTitle = val.auth_title
        this.form.authKey = val.auth_key
        this.form.authId = val.id
      } else {
        this.title = "新增权限"
      }
    },
    async confirm(){
      let res;
      this.form.authId ? (res = await businessAuthOperateEdit(this.form)) : (res = await businessAuthOperateCreate(this.form));
      if(res.code == 200){
        this.$message.success('操作成功')
        this.close()
        this.$parent.initData();
      }
    },
    close(){
      this.dialogFormVisible = false
      this.form = {
        authTitle:"",
        authKey:"",
        isMenu:1,
        authType:0,
        routeId:""
      }
    },
    handleClose(done){
      done();
      this.close()
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .customW{
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title{
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer{
    border-top: 1px solid #e8eaec;
  }
}

</style>
