<template>
  <div class="businessAuthList">
    <div class="derive">
      <el-button size="small" @click="createAuth()">新增权限</el-button>
    </div>
    <div class="auth-table">
      <el-table :data="tableData" header-cell-class-name="table_header" row-key="id" border :tree-props="{children: 'child', hasChildren: 'hasChildren'}">
        <el-table-column prop="auth_title" label="名称"/>
        <el-table-column prop="auth_key" label="KEY"/>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editAuth(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="delAuth(scope.row)">删除</el-button>
            <el-button type="text" size="small" @click="createChildAuth(scope.row)">新增子权限</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <business-auth ref="businessAuth"/>
    <business-child-auth ref="businessChildAuth"/>
  </div>
</template>

<script>
import businessAuth from './components/business-auth.vue';
import BusinessChildAuth from './components/business-child-auth.vue';
import {businessAllAuthList,statusbusinessAuth} from '@/api/businessManage.js';
export default {
  components: { businessAuth, BusinessChildAuth },
  name: 'businessAuthList',

  data() {
    return {
      tableData:[]
    };
  },

  mounted() {
    this.initData()
  },

  methods: {
    async initData(){
      const {code,data} = await businessAllAuthList()
      if(code == 200) {
        this.tableData = data
      }
    },
    editAuth(row){
      if(row.auth_pid == 0){
        this.$refs.businessAuth.isShow(row,"edit")
      } else {
        this.$refs.businessChildAuth.isShow(row,"edit")
      }
    },
    createChildAuth(row){
      this.$refs.businessChildAuth.isShow(row)
    },
    delAuth(row){
      this.$confirm('是否删除当前权限？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async () => {
        const param = {
          authId : row.id,
          authStatus:2,
        }
        const {code} = await statusbusinessAuth(param)
        if(code == 200){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.initData()
        }
      }).catch(() => {
      })
    },
    createAuth(){
      this.$refs.businessAuth.isShow()
    },
  },
};
</script>

<style lang="scss" scoped>
.businessAuthList{
  .derive{
    margin-bottom: 10px;
  }  
  .auth-table{
    /deep/ .table_header{
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>