<template>
  <div>
    <el-dialog
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="100px">
        <el-form-item label="权限名称：">
          <el-input v-model="form.authTitle" placeholder="请输入权限名称" clearable style="width:60%"></el-input>
        </el-form-item>
        <el-form-item label="KEY：">
          <el-input v-model="form.authKey" placeholder="请输入KEY" clearable style="width:60%"></el-input>
        </el-form-item>
        <el-form-item label="是否为菜单：">
          <el-radio-group v-model="form.isMenu">
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="权限类型：" v-if="form.isMenu == 0" >
          <el-radio-group v-model="form.authType">
            <el-radio :label="1">页面</el-radio>
            <el-radio :label="2">功能</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="使用接口：" v-if="form.isMenu == 0">
          <el-select v-model="routeIdList" @change="changeRouteIdList" filterable multiple placeholder="请选择" style="width:60%">
            <el-option
              v-for="item in postList"
              :key="item.id"
              :label="item.route_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm()" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {businessAuthOperateCreate, businessAuthOperateEdit,businessRouteList} from '@/api/businessManage.js';
export default {
  name: "createChildAuth",
  data() {
    return {
      dialogFormVisible:false,
      title:"新增子权限",
      postList:[],
      form:{
        authTitle:"",
        authKey:"",
        isMenu:0,
        authType:1,
        routeId:""
      },
      routeIdList:[],
    };
  },
  methods: {
    async isShow(val,type) {
      this.dialogFormVisible = true
      this.getRouteList()
      if(type == "edit"){
        this.title = "修改子权限"
        this.form.authId = val.id
        this.form.authPid = val.auth_pid
        this.form.isMenu = val.is_menu
        this.form.authType = val.auth_type
        this.form.authTitle = val.auth_title
        this.form.authKey = val.auth_key
        var routeIdlist = []
        val.route_list.map(item => {
          routeIdlist.push(item.id)
        })
        this.routeIdList = routeIdlist
        this.form.routeId = this.routeIdList.join(",")
      } else {
        this.title = "新增子权限"
        this.form.authPid = val.id
      }
    },
    async getRouteList() {
      const {code,data} = await businessRouteList()
      if(code == 200){
        this.postList = data
      }
    },
    async confirm(){
      let res;
      this.form.authId ? (res = await businessAuthOperateEdit(this.form)) : (res = await businessAuthOperateCreate(this.form));
      if(res.code == 200){
        this.$message.success('操作成功')
        this.close()
        this.$parent.initData();
      }
    },
    changeRouteIdList(val){
      this.form.routeId = val.join(",")
    },
    close(){
      this.dialogFormVisible = false
      this.form = {
        authTitle:"",
        authKey:"",
        isMenu:0,
        authType:1,
        routeId:""
      }
      this.routeIdList = []
    },
    handleClose(done){
      done();
      this.close()
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .customW{
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title{
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer{
    border-top: 1px solid #e8eaec;
  }
}

</style>
