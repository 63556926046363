import request from '@/utils/request'

//商家接口列表
export function businessRouteList (params) {
  return request.post('/api/businessRouteList', params)
}

// 商家管理后台权限列表
export function businessAllAuthList (params) {
  return request.post('/api/businessAllAuthList', params)
}

// 创建商家管理后台权限
export function businessAuthOperateCreate (params) {
  return request.post('/api/businessAuthOperateCreate',params)
}

// 修改商家管理后台权限
export function businessAuthOperateEdit (params) {
  return request.post('/api/businessAuthOperateEdit',params)
}

// 商家管理后台角色列表
export function businessAllRoleList (params) {
  return request.post('/api/businessAllRoleList',params)
}

// 创建商家管理后台角色
export function businessCreateRole (params) {
  return request.post('/api/businessCreateRole',params)
}

// 修改商家管理后台角色
export function businessEditRole (params) {
  return request.post('/api/businessEditRole',params)
}

// 商家管理后台角色状态为正常的角色
export function businessStatusRoleList (params) {
  return request.post('/api/businessStatusRoleList',params)
}

// 商家管理后台账号列表
export function listBusinessAdmin (params) {
  return request.post('/api/listBusinessAdmin',params)
}

// 创建商家管理后台账号
export function createBusinessAdmin (params) {
  return request.post('/api/createBusinessAdmin',params)
}

// 修改商家管理后台账号
export function editBusinessAdmin (params) {
  return request.post('/api/editBusinessAdmin',params)
}

//删除商家管理后台账号
export function statusBusinessAdmin (params) {
  return request.post('/api/statusBusinessAdmin',params)
}


export function statusbusinessAuth (params) {
  return request.post('/api/statusbusinessAuth',params)
}